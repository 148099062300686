import React from "react";
import { connect } from "react-redux";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

const mapStateToProps = (state, ownProps) => ({
  readOnly: ownProps.readOnly,
  inputType: ownProps.inputType,
  id: ownProps.id,
  valueId: ownProps.valueId,
  icon: ownProps.icon,
  placeholder: ownProps.placeholder,
  focus: ownProps.focus,
  onFocus: ownProps.onFocus,
  onChange: ownProps.onChange,
  showPassword: ownProps.showPassword,
  setShowPassword: ownProps.setShowPassword,
  popoverHeader: ownProps.popoverHeader,
  popoverMessage: ownProps.popoverMessage,
});

const FormInput = ({
  readOnly,
  inputType,
  id,
  valueId,
  icon,
  placeholder,
  focus,
  onFocus,
  onChange,
  showPassword,
  setShowPassword,
  popoverHeader,
  popoverMessage,
}) => {
  return (
    <>
      <InputGroup
        className={"no-border input-lg" + (focus ? " input-group-focus" : "")}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon} />
          </InputGroupText>
        </InputGroupAddon>
        {
          readOnly ?
          <Input
            type={inputType}
            id={id}
            value={valueId}
            readOnly={readOnly}
          /> :
          <Input
            type={inputType === "password" && showPassword ? "text" : inputType}
            id={id}
            value={valueId}
            placeholder={placeholder}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => onFocus(true)}
            onBlur={() => onFocus(false)}
          />
        }
        {
          inputType !== "password" ? "" :
          <InputGroupAddon addonType="append" id={id + "-append"}>
            <InputGroupText>
              <button className="btn-show-password" type="button" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "HIDE" : "SHOW"}</button>
            </InputGroupText>
          </InputGroupAddon>
        }
        {!popoverHeader === false && (
          <UncontrolledPopover trigger="focus" placement="left" target={inputType === "password" ? id : id}>
            <PopoverHeader style={{ textAlign: "center", color: "black" }}>
              {popoverHeader}
            </PopoverHeader>
            <PopoverBody style={{ fontSize: "12px" }}>
              {popoverMessage}
            </PopoverBody>
          </UncontrolledPopover>
        )}
      </InputGroup>
    </>
  );
};
export default connect(
  mapStateToProps,
  {}
)(FormInput);
