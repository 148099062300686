import { getClient } from "../../components/utils/Config"

export const solomon_api = {
  "urls": [
    {
      "id": "default",
      "url": `${process.env.REACT_APP_KSD_URL}`
    },
    {
      "id": "ksd",
      "url": `${process.env.REACT_APP_KSD_URL}`
    },
    {
      "id": "plv",
      "url": `${process.env.REACT_APP_PLV_URL}`
    },
    {
      "id": "plp",
      "url": `${process.env.REACT_APP_PLP_URL}`
    },
    {
      "id": "bpsu",
      "url": `${process.env.REACT_APP_BPSU_URL}`
    },
    {
      "id": "bipsu",
      "url": `${process.env.REACT_APP_BIPSU_URL}`
    }
  ]
}

export const API = {
  MATERIALS_LOCAL: "localhost:3000",
  MATERIALS_WEB: "https://doaj.org/api/v1/search/articles/",
  // SOLOMON_API: "http://175.41.179.28:8080"
  // SOLOMON_API: "http://192.168.100.10:8080"
  SOLOMON_LOGIN_ENDPOINT: `${process.env.REACT_APP_SOLOMON_LOGIN_ENDPOINT}`,
  SOLOMON_RESOURCE_ENDPOINT: `${process.env.REACT_APP_SOLOMON_RESOURCE_ENDPOINT}`,
  SOLOMON_PASSWORD_CONFIRM_ENDPOINT: `${process.env.REACT_APP_SOLOMON_PASSWORD_CONFIRM_ENDPOINT}`,
  SOLOMON_PASSWORD_CODE_ENDPOINT: `${process.env.REACT_APP_SOLOMON_PASSWORD_CODE_ENDPOINT}`,
  SOLOMON_PASSWORD_RESET_ENDPOINT: `${process.env.REACT_APP_SOLOMON_PASSWORD_RESET_ENDPOINT}`,
  SOLOMON_PASSWORD_FORGOT_ENDPOINT: `${process.env.REACT_APP_SOLOMON_PASSWORD_FORGOT_ENDPOINT}`,
  SOLOMON_SIGNUP_REQUEST_ENDPOINT: `${process.env.REACT_APP_SOLOMON_SIGNUP_REQUEST_ENDPOINT}`,
  SOLOMON_SIGNUP_VERIFY_ENDPOINT: `${process.env.REACT_APP_SOLOMON_SIGNUP_VERIFY_ENDPOINT}`,
  
  GET_SOLOMON_API: function() {
    let clientData = getClient();
    let api = solomon_api.urls.find(item => item.id === clientData.id);
    return api.url;
  }
};