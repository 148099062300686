import React, { Component } from "react";
import { Badge } from "reactstrap";
import "./ResultCard.scss";

class ResultCard extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="col-md-12 result-card">
        <a className="title" href={data.link} target="_blank" rel="noopener noreferrer">
          <h3><b>{data.title}</b></h3>
        </a>
        <div className="author">
          <b>{data.author.trim().length > 0 && <i>{data.author} - </i>}
          <i>{data.publisher}</i>
          {
            (data.year > 0) &&
              (<i>, {data.year}</i>)
          }
          </b>
        </div>
        <p className="summary">{data.summary}</p>
        {
          (data.subjects.length > 0) &&
            (<div className="subjects">
              <b><i>Subjects:</i></b> { data.subjects.map(s => (<Badge key={s} color="info" className="mr-1">{s}</Badge>))}
            </div>)
        }
        {
          (data.keywords.length > 0) &&
            (<div className="subjects">
              <b><i>Keywords:</i></b> { data.keywords.map(s => (<Badge key={s} color="default" className="mr-1">{s}</Badge>))}
            </div>)
        }
      </div>
    );
  }
}

export default ResultCard;
