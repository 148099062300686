import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Form,
    Input, InputGroup, InputGroupAddon,
    InputGroupText,
    PopoverBody, PopoverHeader, UncontrolledPopover
} from "reactstrap";
import { passwordConfirmCode } from "../../redux/actions/reset.actions";
import { DANGER_NOTIF_TYPE, setUINotification } from "../../redux/actions/ui.actions";

const mapStateToProps = (state, ownProps) => ({
    getResetUsername: ownProps.getResetUsername,
    setResetCode: ownProps.setResetCode
});

const ResetRequestCard = ({ passwordConfirmCode, getResetUsername, setResetCode, setUINotification }) => {
    var resetUsername = getResetUsername();

    const [confirmCode, setConfirmCode] = useState("");
    const [firstFocus, setFirstFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const sendConfirmationCode = (e) => {
        e.preventDefault();
        if (confirmCode === '') {
            setUINotification(DANGER_NOTIF_TYPE, "Provide Confirmation Code");
            return;
        }

        setResetCode(confirmCode);
        passwordConfirmCode(resetUsername, confirmCode);
    }

    return (
        <>
            <Card className="card-plain reset-card">
                <Form className="text-center p-5 reset-form" onSubmit={sendConfirmationCode}>
                    <CardHeader className="text-center">
                        {/* <img className="logo" src={logo} alt="KS-Discovery" /> */}
                        <CardTitle className="title-up" tag="h3">
                            {"Reset Confirmation"}
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <InputGroup className={"no-border input-lg"}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons users_circle-08"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" readOnly={true}
                                id="username"
                                placeholder="Username"
                                value={resetUsername}
                            />
                        </InputGroup>
                        <InputGroup
                            className={
                                "no-border input-lg" +
                                (firstFocus ? " input-group-focus" : "")
                            }
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons objects_key-25"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="confirmCode"
                                placeholder="Reset Code"
                                type={showPassword ? "text" : "password"}
                                value={confirmCode}
                                onChange={e => setConfirmCode(e.target.value)}
                                onFocus={() => setFirstFocus(true)}
                                onBlur={() => setFirstFocus(false)}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                <button className="btn-show-password" type="button" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "HIDE" : "SHOW"}</button>
                                </InputGroupText>
                            </InputGroupAddon>
                            <UncontrolledPopover trigger="focus" placement="left" target="confirmCode">
                                <PopoverHeader style={{ textAlign: "center", color: "black" }}>Reset Code</PopoverHeader>
                                <PopoverBody style={{ fontSize: "12px" }}>
                                    Provide the reset code sent to your email
                                </PopoverBody>
                            </UncontrolledPopover>
                        </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button block color="info" className="btn-lg btn-round reset-button" type="submit">{'Verify Code'}</Button>
                    </CardFooter>
                </Form>
            </Card>
        </>
    );
};
export default connect(mapStateToProps, { setUINotification, passwordConfirmCode })(ResetRequestCard);