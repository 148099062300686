import { SET_LOADER, UI_ERROR, UI_NOTIFICATION } from "../actions/ui.actions";

const initState = {
  loading: false,
  global: true,
  uiErrorMessage: "",
};

export const uiReducer = (ui = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADER:
      return { loading: payload.data, global: payload.global };

    case UI_ERROR:
      return { uiErrorMessage: payload.uiErrorMessage };

    case UI_NOTIFICATION:
      return { notification: payload.notification };

    default:
      return ui;
  }
};
