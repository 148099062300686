export const SEARCH = "SEARCH";

export const search = (search_string, filter, page) => ({
  type: SEARCH,
  payload: {
    data: search_string,
    filter,
    page,
  }
});
