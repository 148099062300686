import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { login, loginFailed } from "../../redux/actions/auth.actions";
import { getClient } from "../utils/Config"
import {
  DANGER_NOTIF_TYPE,
  setUINotification,
} from "../../redux/actions/ui.actions";
import ResetModal from "../reset/ResetModal";
import RegisterModal from "../register/SignupModal";
import "./Login.scss";

const mapStateToProps = (state) => ({
  redirect: state.authentication.authenticated,
  errorMessage: state.authentication.errorMessage,
  notification: state.ui.notification
});

const images = [
  require("../assets/bg/1.png"),
  require("../assets/bg/2.png"),
  require("../assets/bg/3.png"),
  require("../assets/bg/4.png"),
  require("../assets/bg/5.png"),
  // require("../assets/bg/6.png"),
  // require("../assets/bg/7.png"),
  require("../assets/bg/8.png"),
  // require("../assets/bg/9.png"),
  require("../assets/bg/10.png"),
  // require("../assets/bg/11.png"),
  // require("../assets/bg/12.png"),
  // require("../assets/bg/13.png"),
  require("../assets/bg/14.png"),
  require("../assets/bg/15.png"),
  // require("../assets/bg/16.png"),
  require("../assets/bg/17.png"),
  require("../assets/bg/18.png"),
  // require("../assets/bg/19.png"),
  // require("../assets/bg/20.png"),
  require("../assets/bg/21.png"),
  require("../assets/bg/22.png"),
  require("../assets/bg/23.png"),
];
const randomNumber = Math.floor(Math.random() * images.length);

var clientData = getClient();

const Login = ({
  login,
  loginFailed,
  redirect,
  history,
  errorMessage,
  notification,
  setUINotification,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstFocus, setFirstFocus] = useState(false);
  const [lastFocus, setLastFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function notifOptions(msg, type, icon = "now-ui-icons ui-1_bell-53") {
    var options = {
      place: "tr",
      message: <div>{msg}</div>,
      type: type,
      icon: icon,
      autoDismiss: 3,
      closeButton: false,
    };
    return options;
  }

  const notifRef = useRef(null); // Ref used for the notification Alet
  const submitForm = (e) => {
    e.preventDefault();
    if (!username || !password) {
      setUINotification(
        DANGER_NOTIF_TYPE,
        "Username and Password must be provided"
      );
      return;
    }

    login(username, password);
  };

  useEffect(
    () => {
      if (redirect) {
        history.push("/");
      }
    },
    [redirect]
  );

  useEffect(
    () => {
      if (!notification || !notification.msg) {
        // Nothing to do
        return;
      }
      notifRef.current.notificationAlert(
        notifOptions(notification.msg, notification.type, notification.icon)
      );
      setUINotification("", "");
    },
    [notification]
  );

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + images[randomNumber] + ")" }}
        />
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form
                  action=""
                  className="form"
                  method=""
                  onSubmit={submitForm}
                >
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <img alt="..." src={require("../assets/" + clientData.logo)} />
                    </div>
                  </CardHeader>
                  <CardBody className="card-login-body">
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        id="username"
                        placeholder={ firstFocus ? "email@example.com" : "Username"}
                        autoComplete="off"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <button className="btn-show-password" type="button" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "HIDE" : "SHOW"}</button>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <NotificationAlert ref={notifRef} />
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      // onClick={(e) => e.preventDefault()}
                      size="lg"
                      type="submit"
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Form>
                <div className="pull-left">
                  <ResetModal />
                </div>
                <div className="pull-right">
                  <RegisterModal />
                </div>
              </Card>
            </Col>
          </Container>
        </div>
        {/* <TransparentFooter /> */}
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  { login, loginFailed, setUINotification }
)(Login);
