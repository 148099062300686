import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import { includes, equals, append } from 'ramda';
import { search } from "../../redux/actions/search.actions";
import { setLoader } from "../../redux/actions/ui.actions";
import selectors from '../../redux/selectors'
import {
  Badge, Collapse, Pagination, PaginationItem, PaginationLink,
  Row, Col, InputGroup, InputGroupAddon, Input,
  Button, Container
} from 'reactstrap'
import "./Search.scss";

import ResultCardList from "../result-card-list/ResultCardList";
import Header from "../header/Header";

const mapStateToProps = state => ({
  redirect: state.authentication.authenticated,
  loading: state.ui.loading,
  globalLoading: state.ui.global,
 ...createStructuredSelector({
  aggregates: selectors.materials.aggregateSelectors,
  totalHits: selectors.materials.totalHitsSelector,
 })(state),
});

const Search = withRouter(({ loading, globalLoading, search, location, aggregates, totalHits }) => {
  const [query, setQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [collapseState, setCollapseState] = useState({});
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(()=> {
    if(location.state && location.state.searchText ){
      setPageIndex(0);
      search(location.state.searchText);
      setQuery(location.state.searchText);
    }
  }, [location.state]);

  useEffect(() => {
    if(query && query !== ""){
      setPageIndex(0);
      search(
        query,
        btoa(JSON.stringify({ filters: selectedFilters})),
        0,
      );
    }
  },[selectedFilters])

  const onSearchClick = () => {
    if (query) {
        setPageIndex(0);
        search(query);
        setSelectedFilters([]);
        setCollapseState({});
    }
  };

  const handleKeyUp = (e) => {
    if(e.which === 13 && query !== ''){
      setPageIndex(0);
      search(query);
      setSelectedFilters([]);
      setCollapseState({});
    }
  };

  const selectFilter = (filter) => {
    if(includes(filter, selectedFilters)){
      setSelectedFilters(selectedFilters.filter(
        (value)=> !equals(value, filter),
      ))
    }else {
      setSelectedFilters(
        append(filter, selectedFilters),
      );
    }
  };

  const refreshFilter = (aggregateKey) => {
    setSelectedFilters(selectedFilters.filter(
      (filter)=> filter.key !== aggregateKey,
    ))
  };

  const isFilterExisting = (aggregateKey) => {
    return selectedFilters.filter(filter => filter.key === aggregateKey).length > 0;
  };

  const handlePageSelection = (pageNumber) => () => {
    setPageIndex(pageNumber);
    search(
      query,
      btoa(JSON.stringify({ filters: selectedFilters})),
      pageNumber,
    );
  };

  const renderBuckets = (buckets = [], aggregateKey = "") => {
    if(buckets.length === 0) {
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <a href="#">No Search Result</a>
        </li>
      );
    }
    if(aggregateKey==='keyword.exact' || aggregateKey==='publicationYear' ) {
      return (
        (buckets.map(({key, count }) => (
          <Collapse key={aggregateKey + "_" + key} isOpen={collapseState[aggregateKey]}>
            <li key={key}
              className="list-group-item d-flex justify-content-between align-items-center"
              onClick={() =>selectFilter({key: aggregateKey, value: key})}
            >
              <a>{key}</a>
              <Badge>{count}</Badge>
          </li>
        </Collapse>
        )))
      )
    }

    if(buckets.length > 3) {
      return (
        <React.Fragment>
          {(buckets.slice(0,3)).map(({key, count }) => (
            <li key={key}
              className="list-group-item d-flex justify-content-between align-items-center"
              onClick={() =>selectFilter({key: aggregateKey, value: key})}
            >
              <a>{key}</a>
              <Badge>{count}</Badge>
          </li>
          ))}
          {(buckets.slice(3)).map(({key, count }) => (
            <Collapse key={aggregateKey + "_" + key} isOpen={collapseState[aggregateKey]}>
              <li key={key}
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() =>selectFilter({key: aggregateKey, value: key})}
              >
                <a>{key}</a>
                <Badge>{count}</Badge>
            </li>
          </Collapse>
          ))}
        </React.Fragment>
      )
    }

    return buckets.map(({key, count }) => (
      <li key={key}
        className="list-group-item d-flex justify-content-between align-items-center"
        onClick={() => selectFilter({key: aggregateKey, value: key})}
      >
        <a>{key}</a>
        <Badge>{count}</Badge>
     </li>
    ))

  }
  const handleBucketCollapse = (aggregateKey) => () => setCollapseState(
    {
      ...collapseState,
      [aggregateKey]: !collapseState[aggregateKey],
    }
  );

  const renderAggregates = () => {
    return aggregates.map(item => {
      const { aggregateKey, displayKey, buckets } = item;
        return (
          <React.Fragment>
            <ul className="list-group search-aggregate" key={aggregateKey + "_" + displayKey}>
                <li className="list-group-item d-flex justify-content-between align-items-center agg-type" key={displayKey}>
                  <b>{ displayKey }</b>
                  { !isFilterExisting(aggregateKey) && buckets && (buckets.length > 3 || ((aggregateKey==='keyword.exact' || aggregateKey==='publicationYear') && buckets.length > 0))
                    && (
                      <Badge color='neutral' onClick={handleBucketCollapse(aggregateKey)}>{!collapseState[aggregateKey] ? '+' : '-'}</Badge>
                    )
                  }
                  { isFilterExisting(aggregateKey) && <Badge color='neutral' onClick={() => refreshFilter(aggregateKey)}>
                      <i
                        className="now-ui-icons arrows-1_refresh-69"
                        title="Reset filter"
                      />
                    </Badge>
                  }
                </li>

                {renderBuckets(buckets, aggregateKey)}
            </ul>
            <br/>
          </React.Fragment>
        )
    });
  }

  const getStartingIndex = (pages) => {
    if (pageIndex < 2 ) {
      return 0;
    }
    if ((pages - pageIndex ) < 3 ) {
      return pages - 5;
    }
    return pageIndex - 2;
  }

  const renderPaginationNumbers = (pages) => {
    if(pages <= 5){
      return Array.apply(null, Array(pages)).map((_, index)=> {
        return (
          <PaginationItem className="search-pagination"
            key={`pagination-${index}`}
            onClick={handlePageSelection(index)}
            active={pageIndex === index}
          >
            <PaginationLink href="#">{index + 1}</PaginationLink>
          </PaginationItem>
          );
        });
     }
     const startIndex = getStartingIndex(pages);
     return Array.apply(null, Array(5)).map((_, index)=> {
      return (
        <PaginationItem
          key={`pagination-${startIndex + index}`}
          onClick={handlePageSelection(startIndex + index)}
          active={pageIndex === startIndex + index}
        >
          <PaginationLink href="#"> {startIndex + index + 1} </PaginationLink>
        </PaginationItem>
        );
      });
  };

  const renderPagination = () => {
    const pages = Math.ceil((totalHits/10));
    if(pages < 2) {
      return <React.Fragment />
    }
    return(
      <Pagination className="pagination search-pagination">
        <PaginationItem disabled={pageIndex === 0}>
          <PaginationLink first href="#" onClick={handlePageSelection(0)} />
        </PaginationItem>
        <PaginationItem disabled={pageIndex === 0}>
          <PaginationLink previous href="#" onClick={handlePageSelection(pageIndex - 1)}/>
        </PaginationItem>
        {renderPaginationNumbers(pages)}
        <PaginationItem disabled={pageIndex + 1 === pages}>
          <PaginationLink next href="#" onClick={handlePageSelection(pageIndex + 1)} />
        </PaginationItem>
        <PaginationItem disabled={pageIndex + 1 === pages}>
          <PaginationLink last href="#" onClick={handlePageSelection(pages - 1)}/>
        </PaginationItem>
      </Pagination>
    )
  };

  const renderSearchResult = () => {
    return (
        <Row>
          <Col key="aggregates" xs="3" className="aggregate-container">
            <a href="#" className="clear-filters" onClick={()=>setSelectedFilters([])}>Clear Filters</a>
            {renderAggregates()}
          </Col>
          <Col key="results" xs="9" className="mb-4">
            <div className="form-row search-form-container">
              <InputGroup className="search-text">
                  <Input value={query}
                    type="text"
                    onChange={e => setQuery(e.target.value)}
                    onKeyUp={handleKeyUp} />
                  <InputGroupAddon addonType="append">
                    <Button outline color="primary" onClick={onSearchClick}>
                      <i className="now-ui-icons ui-1_zoom-bold"></i>
                    </Button>
                  </InputGroupAddon>
              </InputGroup>
            </div>
            <Row className="no-gutters">
              <Col align="left">
                <div className="total-hits"><b>TOTAL HITS:</b>{` ${totalHits}`}</div>
              </Col>
              <Col>{renderPagination()}</Col>
            </Row>
            <Row className="no-gutters search-result">
              <Col>
                <ResultCardList />
              </Col>
            </Row>
            {
              (Math.ceil(totalHits/10) > 1) &&
                (
                  <Row className="no-gutters">
                    <Col align="left">
                      <div className="total-hits"><b>TOTAL HITS:</b>{` ${totalHits}`}</div>
                    </Col>
                    <Col>{renderPagination()}</Col>
                  </Row>
                )
            }
          </Col>
        </Row>
    )
  };

  const renderNoResult = () => {
    return (
      <React.Fragment>
        <Row>
          <Col md={{ size: 8, offset: 2}}>
            <div className="form-row search-form-container">
              <InputGroup className="search-text">
                  <Input value={query}
                    type="text"
                    onChange={e => setQuery(e.target.value)}
                    onKeyUp={handleKeyUp} />
                  <InputGroupAddon addonType="append">
                    <Button outline color="primary" onClick={onSearchClick}>
                      <i className="now-ui-icons ui-1_zoom-bold"></i>
                    </Button>
                  </InputGroupAddon>
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <i className="no-result-icon now-ui-icons ui-1_zoom-bold"/>
            <p>
              We couldn't find a match
              <br/>
              Please try another search
            </p>
          </Col>
        </Row>
      </React.Fragment>
    )
  };

  const searchContent = (loading && !globalLoading) ?  renderSearchResult() : (totalHits > 0 ? renderSearchResult() : renderNoResult());

  return (
    <div className="home-container d-flex w-100 mx-auto flex-column">
      <Header />
      <Container role="main" className="search-container">
        {searchContent}
      </Container>
    </div>
  )
});

export default connect(
  mapStateToProps,
  { search, setLoader },
  )(Search);
