import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Home.scss";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { getClient } from "../utils/Config";
import { setLoader } from "../../redux/actions/ui.actions";
import Header from "../header/Header";

var clientData = getClient();

const Home = withRouter(({ history, setLoader }) => {
  const [text, setText] = useState("");

  const handleSearch = () => {
    if (text !== "") {
      setLoader(true, false);
      history.push({
        pathname: "/search",
        state: {
          searchText: text,
        },
      });
    }
  };
  const handleKeyUp = (e) => {
    if (e.which === 13 && text !== "") {
      handleSearch();
    }
  };

  return (
    <div className="home-container d-flex w-100 h-100 mx-auto flex-column">
      <Header />
      <div role="main" className="solomon-body cover">
        <img
          src={require("../assets/" + clientData.home_logo)}
          className="solomon-logo"
          alt="Logo"
        />
        <div className="lead">
          <InputGroup className="search-text">
            <Input
              id="search-box"
              placeholder="Search for Subject, Book Title or Journal"
              autoComplete="off"
              onChange={(e) => setText(e.target.value)}
              onKeyUp={handleKeyUp}
            />

            <InputGroupAddon addonType="append">
              <Button outline color="primary" onClick={handleSearch}>
                <i className="now-ui-icons ui-1_zoom-bold" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
    </div>
  );
});

// export default Home;
export default connect(null, { setLoader })(Home);
