import React, { useEffect, useState } from "react";
import "react-notification-alert/dist/animate.css";
import { connect } from "react-redux";
import { Button, Modal } from "reactstrap";
import { setUINotification, SUCCESS_NOTIF_TYPE } from "../../redux/actions/ui.actions";
import { passwordResetConfirmationOK, passwordConfirmCodeOK, passwordResetOK } from "../../redux/actions/reset.actions";
import "./Reset.scss";
import ResetConfirmationCard from "./ResetConfirmationCard";
import ResetPasswordCard from "./ResetPasswordCard";
import ResetRequestCard from "./ResetRequestCard";
import ResetSuccessCard from "./ResetSuccessCard";

export const GENERIC_RESPONSE_SUCCESSFUL = "SUCCESS";

const mapStateToProps = state => ({
    requestMessage: state.passwordReset.requestMessage,
    confirmMessage: state.passwordReset.confirmMessage,
    resetMessage: state.passwordReset.resetMessage
});

export var resetUsername = '';
export var resetCode = '';

const ResetModal = ({ requestMessage, confirmMessage, resetMessage, setUINotification, passwordResetConfirmationOK, passwordConfirmCodeOK, passwordResetOK }) => {
    // This will hold the username information
    function setResetUsername(uname) {
        resetUsername = uname;
    }
    function getResetUsername() {
        return resetUsername;
    }

    // This will hold the resetCode information
    function setResetCode(code) {
        resetCode = code;
    }
    function getResetCode() {
        return resetCode;
    }

    const [loginModal, setLoginModal] = useState(false);

    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [successful, setSuccessful] = useState(false);

    useEffect(() => {
        if (!loginModal) {
            setCodeSent(false);
            setConfirmed(false);
            setSuccessful(false);
        }
    }, [loginModal]);

    useEffect(() => {
        if (requestMessage === GENERIC_RESPONSE_SUCCESSFUL) {
            setCodeSent(true);
            setUINotification(SUCCESS_NOTIF_TYPE, 'Reset code is sent to your associated email');
            passwordResetConfirmationOK(null);  // reset the requestMessage
        } else if(!requestMessage) {
            return;
        } else {
            setCodeSent(false);
        }
    }, [requestMessage]);

    useEffect(() => {
        if (confirmMessage === GENERIC_RESPONSE_SUCCESSFUL) {
            setCodeSent(true);
            setConfirmed(true);
            passwordConfirmCodeOK(null);    // reset the confirmMessage
        } else if(!confirmMessage) {
            return;
        } else {
            setConfirmed(false);
        }
    }, [confirmMessage]);

    useEffect(() => {
        if (resetMessage === GENERIC_RESPONSE_SUCCESSFUL) {
            setCodeSent(true);
            setConfirmed(true);
            setSuccessful(true);
            passwordResetOK(null);  // reset the resetMessage
        } else if(!resetMessage) {
            return;
        } else {
            setSuccessful(false);
        }
    }, [resetMessage]);

    return (
        <>
            <Button
                className="btn-link forgot-link"
                color="link"
                onClick={() => setLoginModal(true)}
            >
                Forgot Password?
            </Button>
            <Modal
                isOpen={loginModal}
                centered={true}
                toggle={() => setLoginModal(false)}
                backdrop={true}
            >
                {loginModal && !codeSent ?
                    <ResetRequestCard setResetUsername={setResetUsername} /> :
                    (!confirmed ?
                        <ResetConfirmationCard getResetUsername={getResetUsername} setResetCode={setResetCode} /> :
                        (!successful ?
                            <ResetPasswordCard getResetUsername={getResetUsername} getResetCode={getResetCode} /> :
                            <ResetSuccessCard setLoginModal={setLoginModal} />
                        )
                    )
                }
            </Modal>
        </>
    );
};

export default connect(mapStateToProps, { setUINotification, passwordResetConfirmationOK, passwordConfirmCodeOK, passwordResetOK })(ResetModal);