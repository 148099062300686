export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_CONFIRMATION";
export const passwordResetRequest = (username) => {
    return {
        type: PASSWORD_RESET_REQUEST,
        payload: {
            username
        }
    };
};

export const PASSWORD_RESET_REQUEST_OK = "PASSWORD_RESET_CONFIRMATION_OK";
export const passwordResetConfirmationOK = (requestMessage) => {
    return {
        type: PASSWORD_RESET_REQUEST_OK,
        payload: { 
            requestMessage: requestMessage
        }
    };
}

export const PASSWORD_CONFIRM_CODE = "PASSWORD_CONFIRM_CODE";
export const passwordConfirmCode = (username, confirmCode) => {
    return {
        type: PASSWORD_CONFIRM_CODE,
        payload: {
            username, 
            confirmCode
        }
    };
};

export const PASSWORD_CONFIRM_CODE_OK = "PASSWORD_CONFIRM_CODE_OK";
export const passwordConfirmCodeOK = (confirmMessage) => {
    return {
        type: PASSWORD_CONFIRM_CODE_OK,
        payload: { 
            confirmMessage: confirmMessage
        }
    };
}

export const PASSWORD_FORGOT = "PASSWORD_FORGOT";
export const passwordForgot = (username, confirmCode, newPassword) => {
    return {
        type: PASSWORD_FORGOT,
        payload: {
            username, confirmCode, newPassword
        }
    };
}

export const PASSWORD_RESET = "PASSWORD_RESET";
export const passwordReset = (username, oldPassword, newPassword) => {
    return {
        type: PASSWORD_RESET,
        payload: {
            username, oldPassword, newPassword
        }
    };
};

export const PASSWORD_RESET_OK = "PASSWORD_RESET_OK";
export const passwordResetOK = (resetMessage) => {
    return {
        type: PASSWORD_RESET_OK,
        payload: { 
            resetMessage: resetMessage
        }
    };
}