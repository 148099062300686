import { SET_MATERIALS } from "../actions/materials.actions";


export const materialsReducer = (materials = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_MATERIALS:
      return payload.data;

    default:
      return materials;
  }
};
