import { createSelector } from 'reselect';
import { path } from 'ramda';

export const materialsSelector = state => path(['materials', 'data'], state);
const DEFAULT_AGGS = [
    {
        aggregateKey: 'publicationTypes.keyword',
        displayKey: 'Publication Type',
        buckets: [],
    },
    {
        aggregateKey: 'subjects.term.keyword',
        displayKey: 'Subject',
    }
];

export const aggregateSelectors = createSelector(
    materialsSelector,
    (materialsData) => {
        return path(['aggregateResults'], materialsData) || DEFAULT_AGGS;
});

export const totalHitsSelector = createSelector(
    materialsSelector,
    (materialsData) => {
        return path(['totalHits'], materialsData) || 0;
});