import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Form,
    Input, InputGroup, InputGroupAddon,
    InputGroupText,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover
} from "reactstrap";
import { passwordConfirmCode, passwordReset, passwordResetRequest } from "../../redux/actions/reset.actions";
import { DANGER_NOTIF_TYPE, setUINotification } from "../../redux/actions/ui.actions";

const mapStateToProps = (state, ownProps) => ({
    setResetUsername: ownProps.setResetUsername
});

const ResetRequestCard = ({ passwordResetRequest, setResetUsername, setUINotification }) => {
    const [username, setUsername] = useState("");
    const [firstFocus, setFirstFocus] = useState(false);

    const sendRequestCode = (e) => {
        e.preventDefault();
        if (username === '') {
            setUINotification(DANGER_NOTIF_TYPE, "Provide Username");
            return;
        }
        setResetUsername(username);    // Set the username to store in ResetModal
        passwordResetRequest(username); // Send the Password Reset Request to send out confirmation code
    }

    return (
        <>
            <Card className="card-plain">
                <Form className="text-center p-5 reset-form" onSubmit={sendRequestCode}>
                    <CardHeader className="text-center">
                        {/* <img className="logo" src={logo} alt="KS-Discovery" /> */}
                        <CardTitle className="title-up" tag="h3">
                            {"Password Reset Request"}
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <InputGroup
                            className={
                                "no-border input-lg" +
                                (firstFocus ? " input-group-focus" : "")
                            }
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons users_circle-08"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text"
                                id="username"
                                placeholder={firstFocus ? "email@example.com" : "Username"}
                                value={username}
                                autoComplete='off'
                                autoCorrect='off'
                                autoCapitalize='off'
                                spellCheck='false'
                                onChange={e => setUsername(e.target.value)}
                                onBlur={() => setFirstFocus(false)}
                                onFocus={() => setFirstFocus(true)}
                            />
                            <UncontrolledPopover trigger="focus" placement="right" target="username">
                                <PopoverHeader style={{ textAlign: "center", color: "black" }}>Username</PopoverHeader>
                                <PopoverBody style={{ fontSize: "12px" }}>
                                    Provide your username / email address
                                </PopoverBody>
                            </UncontrolledPopover>
                        </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button block className="btn-lg btn-round reset-button"
                            color="info"
                            type="submit">{'Send Reset Code'}
                        </Button>
                    </CardFooter>
                </Form>
            </Card>
        </>
    );
};
export default connect(mapStateToProps, { setUINotification, passwordResetRequest, passwordConfirmCode, passwordReset })(ResetRequestCard);