const config = require('../config.json');
const domain = require('../domain.json');


// Get the client based on the host
export function getClient() {
    // Get the current host (including port if existing)
    let code = domain.find(item => item.id === window.location.host.toLowerCase());
    if(!code) {
        code = domain.find(item => item.id === 'default');
    }
    return config.find(item => item.id === code.clientCode);
}