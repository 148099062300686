import React from "react";
import { connect } from "react-redux";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
} from "reactstrap";

const mapStateToProps = (state, ownProps) => ({
  setSignupModal: ownProps.setSignupModal,
});

const SignupSuccessfulCard = ({ setSignupModal }) => {
  return (
    <>
      <Form className="text-center p-5 reset-form">
        <CardHeader className="text-center">
          <CardTitle className="title-up" tag="h3">
            Registration Complete!
          </CardTitle>
        </CardHeader>
        <CardBody>
          Your account has been created successfully!
          <br />
          You may now login using your credentials.
        </CardBody>
        <CardFooter className="text-center">
          <Button
            block
            color="info"
            className="btn-lg btn-round reset-button"
            onClick={() => setSignupModal(false)}
          >
            {"Return To Login"}
          </Button>
        </CardFooter>
      </Form>
    </>
  );
};
export default connect(
  mapStateToProps,
  {}
)(SignupSuccessfulCard);
