import { css } from "@emotion/core";
import React from "react";
import "react-notification-alert/dist/animate.css";
import { connect } from "react-redux";
import RingLoader from "react-spinners/RingLoader";
import { Modal, ModalBody } from "reactstrap";
import { setLoader } from "../../redux/actions/ui.actions";
import "./Loader.scss";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const modalCss = css`
  background: "transparent"
  background-color: "transparent !important"
`;

const mapStateToProps = (state) => ({
  loading: state.ui.loading,
  global: state.ui.global,
});

const LoadingModal = ({ loading, global }) => {
  // let [color, setColor] = useState("#ffc107");

  return (
    <>
      {/* <Button
        className="btn-link forgot-link"
        color="link"
        onClick={() => setLoader(true)}
      >
        Loading...
      </Button> */}
      {
        global === true ?
          <Modal
            isOpen={loading}
            centered={true}
            // toggle={() => setLoader(false)}
            backdrop={true}
            className="loader"
            keyboard={false}
            style={modalCss}
          >
            <ModalBody>
              <RingLoader
                color={"#dbb209"}
                loading={loading}
                css={override}
                size={80}
              />
            </ModalBody>
          </Modal> :
          <></>
      }
    </>
  );
};

export default connect(
  mapStateToProps,
  { setLoader }
)(LoadingModal);
