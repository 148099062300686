import { API_REQUEST, apiSuccess, apiError } from "../../actions/api.actions";

export const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);
  if (action.type.split(" ")[1] === API_REQUEST) {
    const { entity, method, url, token } = action.payload.meta;
    
    if (method === "GET") {
      let data = {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`, 
          "Content-Type": "application/json"
        }
      };
      fetch(encodeURI(url), data)
        .then(res => res.json())
        .then(data => dispatch(apiSuccess(data, entity)))
        .catch(error => dispatch(apiError(error, entity)));
    }
    if (method === "POST") {
      let data = {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json"
        }
      };
      fetch(encodeURI(url), data)
        .then(res => res.json())
        .then(data => dispatch(apiSuccess(data, entity)))
        .catch(error => dispatch(apiError(error, entity)));
    }
  }  
};
