import { apiRequest, API_ERROR, API_SUCCESS } from "../../actions/api.actions";
import {
  passwordConfirmCodeOK,
  passwordResetConfirmationOK,
  passwordResetOK,
  PASSWORD_CONFIRM_CODE,
  PASSWORD_RESET,
  PASSWORD_RESET_REQUEST,
  PASSWORD_FORGOT
} from "../../actions/reset.actions";
import {
  DANGER_NOTIF_TYPE,
  setLoader,
  setUINotification,
} from "../../actions/ui.actions";
import { API } from "../../constants/api";

export const passwordRequestMiddleware = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_PASSWORD_CONFIRM_ENDPOINT}`,
          PASSWORD_RESET_REQUEST
        )
      );
      break;

    case `${PASSWORD_RESET_REQUEST} ${API_SUCCESS}`:
      const { message, status } = action.payload.data;

      dispatch(setLoader(false));
      if (status === 200) {
        dispatch(passwordResetConfirmationOK(message));
      } else if (status === 404) {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, "Username do not exists"));
      } else {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, message));
      }
      break;

    case `${PASSWORD_RESET_REQUEST} ${API_ERROR}`:
      dispatch(setLoader(false));
      dispatch(
        setUINotification(
          DANGER_NOTIF_TYPE,
          "Error Occured! Please contact your System Administrator"
        )
      );
      break;

    default:
      break;
  }
};

export const passwordConfirmMiddleware = ({ dispatch }) => (next) => (
  action
) => {
  next(action);
  switch (action.type) {
    case PASSWORD_CONFIRM_CODE:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_PASSWORD_CODE_ENDPOINT}`,
          PASSWORD_CONFIRM_CODE
        )
      );
      break;

    case `${PASSWORD_CONFIRM_CODE} ${API_SUCCESS}`:
      const { message, status } = action.payload.data;

      dispatch(setLoader(false));
      if (status === 200) {
        dispatch(passwordConfirmCodeOK(message));
      } else if (status === 406) {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, "Reset Code Not Valid"));
      } else {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, message));
      }
      break;

    case `${PASSWORD_CONFIRM_CODE} ${API_ERROR}`:
      dispatch(setLoader(false));
      dispatch(
        setUINotification(
          DANGER_NOTIF_TYPE,
          "Error Occured! Please contact your System Administrator"
        )
      );
      break;

    default:
      break;
  }
};
export const passwordForgotMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  // eslint-disable-next-line default-case
  switch (action.type) {
    case PASSWORD_FORGOT:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_PASSWORD_FORGOT_ENDPOINT}`,
          PASSWORD_FORGOT
        )
      );
      break;

    case `${PASSWORD_FORGOT} ${API_SUCCESS}`:
      const { message, status } = action.payload.data;

      dispatch(setLoader(false));
      if (status === 200) {
        dispatch(passwordResetOK(message));
      } else {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, message));
      }
      break;

    case `${PASSWORD_FORGOT} ${API_ERROR}`:
      dispatch(setLoader(false));
      dispatch(
        setUINotification(
          DANGER_NOTIF_TYPE,
          "Error Occured! Please contact your System Administrator"
        )
      );
      break;

    default:
      break;
  }
};
export const passwordResetMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  // eslint-disable-next-line default-case
  switch (action.type) {
    case PASSWORD_RESET:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_PASSWORD_RESET_ENDPOINT}`,
          PASSWORD_RESET
        )
      );
      break;

    case `${PASSWORD_RESET} ${API_SUCCESS}`:
      const { message, status } = action.payload.data;

      dispatch(setLoader(false));
      if (status === 200) {
        dispatch(passwordResetOK(message));
      } else if (status === 406) {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, "Current Password is NOT Valid"));
      } else {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, message));
      }
      break;

    case `${PASSWORD_RESET} ${API_ERROR}`:
      dispatch(setLoader(false));
      dispatch(
        setUINotification(
          DANGER_NOTIF_TYPE,
          "Error Occured! Please contact your System Administrator"
        )
      );
      break;

    default:
      break;
  }
};
