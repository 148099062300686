import { PASSWORD_CONFIRM_CODE_OK, PASSWORD_RESET_OK, PASSWORD_RESET_REQUEST_OK } from "../actions/reset.actions";


export const passwordResetConfirmationReducer = (passwordReset = [], action ) => {
    const { payload } = action;

    switch (action.type) {
        case PASSWORD_RESET_REQUEST_OK:
            return { requestMessage: payload.requestMessage };
            
        case PASSWORD_CONFIRM_CODE_OK:
            return {confirmMessage: payload.confirmMessage };

        case PASSWORD_RESET_OK:
            return {resetMessage: payload.resetMessage };

        default:
            return passwordReset;
    }
};