import {
  signupRequestOK,
  signupVerificationOK,
  SIGNUP_REQ,
  SIGNUP_VERIF,
} from "../../actions/signup.actions";
import { apiRequest, API_ERROR, API_SUCCESS } from "../../actions/api.actions";
import {
  DANGER_NOTIF_TYPE,
  setLoader,
  setUINotification,
} from "../../actions/ui.actions";
import { API } from "../../constants/api";

export const signupRequestMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case SIGNUP_REQ:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_SIGNUP_REQUEST_ENDPOINT}`,
          SIGNUP_REQ
        )
      );
      break;

    case `${SIGNUP_REQ} ${API_SUCCESS}`:
      const { message, status } = action.payload.data;

      dispatch(setLoader(false));
      if (status === 200) {
        dispatch(signupRequestOK(message));
      } else if (status === 404) {
          dispatch(setUINotification(DANGER_NOTIF_TYPE, "Organization and/or Organization Code is NOT Valid, Contact your System Administrator"));
      } else if (status === 406) {
          dispatch(setUINotification(DANGER_NOTIF_TYPE, "Organization Code is Expired OR User Account Already Exists, Contact your System Administrator"));
      } else {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, message));
      }
      break;

    case `${SIGNUP_REQ} ${API_ERROR}`:
      dispatch(setLoader(false));
      dispatch(
        setUINotification(
          DANGER_NOTIF_TYPE,
          "Error Occured! Please contact your System Administrator"
        )
      );
      break;

    default:
      break;
  }
};

export const signupVerificationMiddleware = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  switch (action.type) {
    case SIGNUP_VERIF:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_SIGNUP_VERIFY_ENDPOINT}`,
          SIGNUP_VERIF
        )
      );
      break;

    case `${SIGNUP_VERIF} ${API_SUCCESS}`:
      const { message, status } = action.payload.data;

      dispatch(setLoader(false));
      if (status === 200) {
        dispatch(signupVerificationOK(message));
      } else {
        dispatch(setUINotification(DANGER_NOTIF_TYPE, message));
      }
      break;

    case `${SIGNUP_VERIF} ${API_ERROR}`:
      dispatch(setLoader(false));
      dispatch(
        setUINotification(
          DANGER_NOTIF_TYPE,
          "Error Occured! Please contact your System Administrator"
        )
      );
      break;

    default:
      break;
  }
};
