import React from "react";
import { connect } from "react-redux";
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Form
} from "reactstrap";

const mapStateToProps = (state, ownProps) => ({
    setLoginModal: ownProps.setLoginModal
});

const ResetSuccessCard = ({ setLoginModal }) => {
    return (
        <>
            <Card className="card-plain reset-card">
                <Form className="text-center p-5 reset-form">
                    <CardHeader className="text-center">
                        {/* <img className="logo" src={logo} alt="KS-Discovery" /> */}
                        <CardTitle className="title-up" tag="h3">
                            Password Reset
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        Your password has been reset successfully!<br />
                        You may now login using your new credentials.
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button block color="info" className="btn-lg btn-round reset-button" onClick={() => setLoginModal(false)}>{'Back To Login'}</Button>
                    </CardFooter>
                </Form>
            </Card>
        </>
    );
};
export default connect(mapStateToProps, { })(ResetSuccessCard);