import { createStore, compose, applyMiddleware } from "redux";
import appState from "./reducers";
import { materialsMiddleware } from "./middleware/app/materials";
import { authMiddleware } from "./middleware/app/auth";
import {
  passwordRequestMiddleware,
  passwordConfirmMiddleware,
  passwordResetMiddleware,
  passwordForgotMiddleware
} from "./middleware/app/reset";
import { coreMiddleware } from "./middleware/core";
import { signupRequestMiddleware, signupVerificationMiddleware } from "./middleware/app/signup";

const enhancer = compose(
  applyMiddleware(
    ...coreMiddleware,
    authMiddleware,
    materialsMiddleware,
    passwordRequestMiddleware,
    passwordConfirmMiddleware,
    passwordResetMiddleware,
    passwordForgotMiddleware,
    signupRequestMiddleware,
    signupVerificationMiddleware
  )
);

const store = createStore(appState, {}, enhancer);

export default store;
