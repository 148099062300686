import React from "react";
import ContentLoader from "react-content-loader";

const ResultCardLoader = props => (
  <ContentLoader viewBox="0 0 700 700" height={"auto"} width={"100%"} {...props}>
    <rect x="0" y="10" rx="4" ry="4" width="90%" height="40" />
    <rect x="0" y="55" rx="4" ry="4" width="300" height="20" />
    <rect x="0" y="85" rx="4" ry="4" width="100%" height="80" />
    <rect x="0" y="170" rx="4" ry="4" width="60" height="20" />
    <rect x="65" y="170" rx="4" ry="4" width="120" height="20" />
    <rect x="190" y="170" rx="4" ry="4" width="40" height="20" />

    <rect x="0" y="220" rx="4" ry="4" width="90%" height="40" />
    <rect x="0" y="265" rx="4" ry="4" width="300" height="20" />
    <rect x="0" y="295" rx="4" ry="4" width="100%" height="80" />
    <rect x="0" y="380" rx="4" ry="4" width="60" height="20" />
    <rect x="65" y="380" rx="4" ry="4" width="120" height="20" />
    <rect x="190" y="380" rx="4" ry="4" width="40" height="20" />    
  </ContentLoader>
);

export default ResultCardLoader;
