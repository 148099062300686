import { combineReducers } from "redux";
import { materialsReducer } from "./materials.reducer";
import { uiReducer } from "./ui.reducer";
import { searchReducer } from "./search.reducer";
import { authReducer } from "./auth.reducer";
import { passwordResetConfirmationReducer } from "./reset.reducer";
import { signupReducer } from "./signup.reducer";

const rootReducer = combineReducers({
  authentication: authReducer,
  materials: materialsReducer,
  ui: uiReducer,
  search: searchReducer,
  passwordReset: passwordResetConfirmationReducer,
  signup: signupReducer
});

export default rootReducer;
