export const MATERIALS = "[MATERIALS]";

export const FETCH_MATERIALS = `${MATERIALS} Fetch`;
export const SET_MATERIALS = `${MATERIALS} Set`;

export const fetchMaterials = (query, filter = "", page = 0) => ({
  type: FETCH_MATERIALS,
  payload: {
    data: query,
    filter,
    page,
  }
});

export const setMaterials = materials => ({
  type: SET_MATERIALS,
  payload: {
    data: materials
  }
});
