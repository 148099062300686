import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  CardBody,
  // CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { signupRequest } from "../../redux/actions/signup.actions";
import FormInput from "../../components/utils/FormInput";
import { passwordReset } from "../../redux/actions/reset.actions";
import {
  DANGER_NOTIF_TYPE,
  setUINotification,
} from "../../redux/actions/ui.actions";
import {
  isEmailValid,
  isEmptyOrNotDefined,
  isPasswordValid,
  MAX_PASSWORD_LEGNTH,
  MIN_PASSWORD_LENGTH,
} from "../../services/ValidationUtils";

const mapStateToProps = (state, ownProps) => ({
  setSignupEmail: ownProps.setSignupEmail,
});

const SignupCard = ({ setSignupEmail, setUINotification, signupRequest }) => {
  const [firstFocus, setFirstFocus] = useState(false);
  const [lastFocus, setLastFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const signupNow = (e) => {
    e.preventDefault();

    if (isEmptyOrNotDefined(firstName)) {
      setUINotification(DANGER_NOTIF_TYPE, "Provide your First Name");
      return;
    } else if (isEmptyOrNotDefined(lastName)) {
      setUINotification(DANGER_NOTIF_TYPE, "Provide your Last Name");
      return;
    } else if (isEmptyOrNotDefined(email)) {
      setUINotification(DANGER_NOTIF_TYPE, "Provide your Email");
      return;
    } else if (!isEmailValid(email)) {
      setUINotification(DANGER_NOTIF_TYPE, "Email is invalid");
      return;
    } else if (isEmptyOrNotDefined(password)) {
      setUINotification(DANGER_NOTIF_TYPE, "Provide new password");
      return;
    } else if (
      isEmptyOrNotDefined(confirmPassword) ||
      password !== confirmPassword
    ) {
      setUINotification(
        DANGER_NOTIF_TYPE,
        "Password confirmation do not match"
      );
      return;
    } else if (!isPasswordValid(password)) {
      setUINotification(DANGER_NOTIF_TYPE, "Password must adhere to rules");
      return;
    } else if (isEmptyOrNotDefined(orgCode)) {
      setUINotification(
        DANGER_NOTIF_TYPE,
        "Provide the Organization Code from your System Administrator"
      );
      return;
    }

    setSignupEmail(email);
    signupRequest({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      orgCode: orgCode,
    });
  };

  return (
    <>
      <Form className="text-center p-5 register-form" onSubmit={signupNow}>
        <CardHeader className="text-center">
          <CardTitle className="title-up" tag="h3">
            Sign Up
          </CardTitle>
          <CardTitle tag="h5">
            Use the Organization Code provided by your System Administrator
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Container>
            <Row>
              <Col>
                <FormInput
                  inputType="text"
                  id="orgCode"
                  valueId={orgCode}
                  onChange={setOrgCode}
                  icon="now-ui-icons objects_key-25"
                  placeholder="Organization Code"
                  focus={firstFocus}
                  onFocus={setFirstFocus}
                  popoverHeader="Organization Code"
                  popoverMessage="Organization Code as provided by your System Administrator"
                />
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  inputType="text"
                  id="firstName"
                  valueId={firstName}
                  onChange={setFirstName}
                  icon="now-ui-icons users_circle-08"
                  placeholder="First Name"
                  focus={firstFocus}
                  onFocus={setFirstFocus}
                  popoverHeader="First Name"
                  popoverMessage="Provide your First Name"
                />
              </Col>
              </Row>
            <Row>
              <Col>
                <FormInput
                  inputType="text"
                  id="lastName"
                  valueId={lastName}
                  onChange={setLastName}
                  icon="now-ui-icons users_circle-08"
                  placeholder="Last Name"
                  focus={firstFocus}
                  onFocus={setFirstFocus}
                  popoverHeader="Last Name"
                  popoverMessage="Provide your Last Name"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  inputType="text"
                  id="email"
                  valueId={email}
                  onChange={setEmail}
                  icon="now-ui-icons ui-1_email-85"
                  placeholder="Email"
                  focus={firstFocus}
                  onFocus={setFirstFocus}
                  popoverHeader="Email"
                  popoverMessage="Provide your email address"
                />
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  inputType="password"
                  id="password"
                  valueId={password}
                  onChange={setPassword}
                  icon="now-ui-icons ui-1_lock-circle-open"
                  placeholder="Password"
                  focus={firstFocus}
                  onFocus={setFirstFocus}
                  popoverHeader="Password Strength"
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  popoverMessage={
                    <ul>
                      <li>
                        Must be {MIN_PASSWORD_LENGTH} to {MAX_PASSWORD_LEGNTH}{" "}
                        characters
                      </li>
                      <li>Have at least 1 upper case character</li>
                      <li>Have at least 1 lower case character</li>
                      <li>Have at least a number OR symbol (!@#\$%^\&-_+=)</li>
                    </ul>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  inputType="password"
                  id="confirmPassword"
                  valueId={confirmPassword}
                  onChange={setConfirmPassword}
                  icon="now-ui-icons ui-1_lock-circle-open"
                  placeholder="Confirm Password"
                  focus={lastFocus}
                  onFocus={setLastFocus}
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                  popoverHeader="Confirm Password"
                  popoverMessage="Must be the same as the Password provided"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  block
                  color="info"
                  className="btn-lg btn-round reset-button"
                  type="submit"
                >
                  {"Get Started"}
                </Button>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Form>
    </>
  );
};
export default connect(
  mapStateToProps,
  { passwordReset, setUINotification, signupRequest }
)(SignupCard);
