import React, { Component } from "react";
import "./ResultCardList.scss";
import ResultCard from "../result-card/ResultCard";
import ResultCardLoader from "./ResultCardLoader";

import store from "../../redux/store";


class ResultCardList extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      render: false
    };
  }

  // componentWillMount() {
  componentDidMount() {
    store.subscribe(this.onStoreChange);
  }

  prepareData(materials) {
    return (materials || []).map((m, index) => {
      return {
        title: m.title,
        author: m.authors.join(", "),
        publisher: m.publisher,
        year: m.publicationYear,
        summary: m.description,
        link: m.url,
        // subjects: m.subjects.join(", "),
        subjects: m.subjects,
        keywords: m.keywords,
        key: index
      };
    });
  }

  onStoreChange = () => {
    const state = store.getState();
    const { materials, ui } = state;

    if (materials && materials.data) {
      this.setState({
        data: this.prepareData(materials.data.resourceResults),
        // loading: ui.loading.data,
        loading: ui.loading,
        render: true
      });
    } else {
      this.setState({
        // loading: ui.loading.data,
        loading: ui.loading,
        render: true
      });
    }
  };

  render() {
    if (this.state.render) {
      return (
        <div id="results">
          {this.state.loading ? (
            <ResultCardLoader />
          ) : (
            this.getContent()
          )}
        </div>
      );
    }
    return false;
  }

  getContent() {
    if (this.state.data.length) {
      return this.state.data.map(d => (
        <div className="row" key={d.key}>
          <ResultCard data={d} />
        </div>
      ));
    }
  }
}

export default ResultCardList;
