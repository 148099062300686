import { SEARCH } from "../actions/search.actions";

export const searchReducer = (search_string = "", action) => {
  const { payload } = action;
  switch (action.type) {
    case SEARCH:
      return payload.data;

    default:
      return search_string;
  }
};
