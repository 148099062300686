import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from 'react-avatar';
import "./Header.scss";
import { getClient } from "../utils/Config";
import { logout } from "../../redux/actions/auth.actions";
import {
  Navbar,
  NavbarBrand,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
  Button,
  Container
} from "reactstrap";

const mapStateToProps = state => ({
  firstName: state.authentication.firstName,
  lastName: state.authentication.lastName,
  email: state.authentication.email,
  institutionName: state.authentication.institutionName,
  redirect: state.authentication.authenticated,
});

var clientData = getClient();

const Header = withRouter(({ logout, firstName, lastName, email, institutionName, history, redirect, }) => {
  useEffect(() => {
    if (!redirect) {
      history.push('/login');
    }
  }, [redirect])


  const [isDropdownOpen, setDropDownOpen] = useState(false);

  const onLogoutClick = () => {
    logout();
  };

  const onDropDownToggle = () => setDropDownOpen(!isDropdownOpen);


  return (
    <Navbar className="solomon-header" expand="md" fixed="top">
      <NavbarBrand className="solomon-brand" href="/">
        <img src={require("../assets/" + clientData.header_logo)} alt="KSD Logo"/>
      </NavbarBrand>
      <NavItem className="masthead-nav nav justify-content-end">
        <Dropdown isOpen={isDropdownOpen} toggle={onDropDownToggle}>
          <DropdownToggle
            data-toggle="dropdown"
            href="#pablo"
            id="navbarDropdown"
            tag="a"
            onClick={(e) => e.preventDefault()}
          >
            <Avatar name={firstName + " " + lastName} maxInitials={3} size={40} round="20px" textSizeRatio={1.75} color={Avatar.getRandomColor('sitebase', ['grey', 'orange', 'green'])} />
          </DropdownToggle>
          <DropdownMenu right className="solomon-dropdown">
            <Container>
              <Row>
                <Col className="mx-auto mr-auto text-center mt-4 mb-2" md="12" xl="18">
                  <Avatar name={firstName + " " + lastName} maxInitials={3} size={60} round="60px" textSizeRatio={1.75} color={Avatar.getRandomColor('sitebase', ['grey', 'orange', 'green'])} />
                </Col>
              </Row>
              <Row>
                <Col className="mx-auto mr-auto text-center mt-2" md="12" xl="18">
                  {firstName + " " + lastName}
                </Col>
              </Row>
              <Row >
                <Col className="mx-auto mr-auto text-center mb-2" md="12" xl="18">
                  {email}
                </Col>
              </Row>
              <Row >
                <Col className="mx-auto mr-auto text-center mt-2" md="12" xl="18">
                  {institutionName}
                </Col>
              </Row>
              {
                true ? '' :
                  <Row>
                    <Col className="mx-auto mr-auto text-center" md="12" xl="18">
                      <Button className="btn-round" color="primary" onClick={() => history.push('/profile')}>
                        Manage Profile
                      </Button>
                    </Col>
                  </Row>
              }
            </Container>
            <DropdownItem divider />
            <DropdownItem onClick={onLogoutClick} className="solomon-dropdown-item">Sign Out</DropdownItem>
          </DropdownMenu>
        </Dropdown >
      </NavItem>
    </Navbar>
  )
});

export default connect(
  mapStateToProps,
  {
    logout,
  }
)(Header);
