export const LOGIN = "Login";
export const SET_CURRENT_USER = "Set Current User";
export const LOGOUT_SUCCESS = "Logout Success";
export const LOGOUT = "Logout";
export const LOGIN_FAILED = "Login Failed";

export const login = (username, password) => {
  return {
    type: LOGIN,
    payload: {
        username: username,
        password: password
    }
  };
};

export const loginFailed = (errorMessage) => {
  return {
    type: LOGIN_FAILED,
    payload: {
      errorMessage
    }
  };
};

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user
  }
};

export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
}