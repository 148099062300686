import { createBrowserHistory } from "history";
import * as qs from "query-string";

const history = createBrowserHistory();

function addQueryParams(parameters) {
  history.push({ pathname: "", search: qs.stringify(parameters) });
}

function subscribeToNavigatorStatus(cbOnOnline, cbOnOffline) {
  window.addEventListener("online", cbOnOnline);
  window.addEventListener("offline", cbOnOffline);
}

export { subscribeToNavigatorStatus, addQueryParams, history };
