import {
  FETCH_MATERIALS,
  MATERIALS,
  setMaterials,
  fetchMaterials,
} from "../../actions/materials.actions";
import { apiRequest, API_SUCCESS } from "../../actions/api.actions";
import { API } from "../../constants/api";
import { setLoader } from "../../actions/ui.actions";
import { SEARCH } from "../../actions/search.actions";

const getFilterString = (filter) => (filter !== "" ? `&filter=${filter}` : "");

export const materialsMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SEARCH:
      // dispatch(setLoader({ state: true, global: false }));
      dispatch(setLoader(true, false));
      dispatch(
        fetchMaterials(
          action.payload.data,
          action.payload.filter,
          action.payload.page
        )
      );
      break;

    case FETCH_MATERIALS:
      // dispatch(setLoader({ state: true, global: false }));
      dispatch(
        apiRequest(
          null,
          "GET",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_RESOURCE_ENDPOINT}?search=${
            action.payload.data
          }${getFilterString(action.payload.filter)}&page=${
            action.payload.page
          }`,
          MATERIALS,
          localStorage.getItem("token")
        )
      );
      break;

    case `${MATERIALS} ${API_SUCCESS}`:
      dispatch(setMaterials(action.payload));
      // dispatch(setLoader({ state: false, global: false }));
      dispatch(setLoader(false, false));
      break;
  }
};
