import { SET_CURRENT_USER, LOGOUT_SUCCESS, LOGIN_FAILED } from "../actions/auth.actions";

export const authReducer = (state = {
  authenticated: false,
  firstName: localStorage.getItem("firstName"),
  lastName: localStorage.getItem("lastName"),
  email: localStorage.getItem("email"),
  institutionName: localStorage.getItem("institutionName"),
  token: localStorage.getItem("token"),
  errorMessage: '',
}, action) => {
  switch (action.type) {
    
    case LOGOUT_SUCCESS:
      return {...state, authenticated: false, token: null}
    
    case SET_CURRENT_USER:
      const {firstName, lastName, email, institutionName, token} = action.payload;
    
      return {
        ...state,
        authenticated: true,
        firstName: firstName,
        lastName: lastName,
        email: email,
        institutionName: institutionName,
        token: token,
        errorMessage: '',
      }
    
    case LOGIN_FAILED:
      const { errorMessage } = action.payload;
    
      return {
        ...state,
        authenticated: false,
        errorMessage,
      }

    default:
      return state;
  }
};
