import { SIGNUP_REQ_OK, SIGNUP_VERIF_OK } from "../actions/signup.actions";

export const signupReducer = (signup = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case SIGNUP_REQ_OK:
      return { signupMessage: payload.signupMessage };

    case SIGNUP_VERIF_OK:
      return { verificationMessage: payload.verificationMessage };

    default:
      return signup;
  }
};
