import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Form,
    Input, InputGroup, InputGroupAddon,
    InputGroupText,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover
} from "reactstrap";
import { passwordForgot } from "../../redux/actions/reset.actions";
import { DANGER_NOTIF_TYPE, setUINotification } from "../../redux/actions/ui.actions";

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LEGNTH = 16;

const mapStateToProps = (state, ownProps) => ({
    getResetUsername: ownProps.getResetUsername,
    getResetCode: ownProps.getResetCode
});

const ResetRequestCard = ({ getResetUsername, getResetCode, passwordForgot, setUINotification }) => {
    var resetUsername = getResetUsername();

    const [firstFocus, setFirstFocus] = useState(false);
    const [lastFocus, setLastFocus] = useState(false);

    // const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const sendResetPassword = (e) => {
        e.preventDefault();
        // if (oldPassword === '' && newPassword === '' && confirmPassword === '') {
        if (newPassword === '' && confirmPassword === '') {
            setUINotification(DANGER_NOTIF_TYPE, "Provide Information");
            return;
        // } else if (oldPassword === '') {
        //     setUINotification(DANGER_NOTIF_TYPE, "Provide your current password");
        //     return;
        } else if (newPassword === '') {
            setUINotification(DANGER_NOTIF_TYPE, "Provide new password");
            return;
        } else if (confirmPassword === '' || newPassword !== confirmPassword) {
            setUINotification(DANGER_NOTIF_TYPE, "'New Password' and 'Confirm New Password' must match");
            return;
        // } else if (!isPasswordVAlid(oldPassword, newPassword)) {
        } else if (!isPasswordValid(newPassword)) {
            setUINotification(DANGER_NOTIF_TYPE, "Password must adhere to rules defined");
            return;
        }
        passwordForgot(resetUsername, getResetCode(), newPassword);
    }

    // const isPasswordValid = (oldPassword, newPassword) => {
    const isPasswordValid = (newPassword) => {
        if (newPassword.length >= MIN_PASSWORD_LENGTH
            && newPassword.length <= MAX_PASSWORD_LEGNTH
            && /[A-Z]/.test(newPassword)
            && /[a-z]/.test(newPassword)
            && (/\d/.test(newPassword)
                || /\W/.test(newPassword))) {
            // && oldPassword !== newPassword) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Card className="card-plain reset-card">
                <Form className="text-center p-5 reset-form" onSubmit={sendResetPassword}>
                    <CardHeader className="text-center">
                        {/* <img className="logo" src={logo} alt="KS-Discovery" /> */}
                        <CardTitle className="title-up" tag="h3">
                            {"Password Reset"}
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {/* <InputGroup
                            className={
                                "no-border input-lg" +
                                (firstFocus ? " input-group-focus" : "")
                            }
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons ui-1_lock-circle-open"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="oldPassword"
                                placeholder="Current Password"
                                type={showPassword ? "text" : "password"}
                                value={oldPassword}
                                onChange={e => setOldPassword(e.target.value)}
                                onFocus={() => setFirstFocus(true)}
                                onBlur={() => setFirstFocus(false)}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                <button className="btn-show-password" type="button" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "HIDE" : "SHOW"}</button>
                                </InputGroupText>
                            </InputGroupAddon>
                            <UncontrolledPopover trigger="focus" placement="left" target="oldPassword">
                                <PopoverHeader style={{ textAlign: "center", color: "black" }}>Current Password</PopoverHeader>
                                <PopoverBody style={{ fontSize: "12px" }}>
                                    Provide your current password
                                </PopoverBody>
                            </UncontrolledPopover>
                        </InputGroup>
                        <hr /> */}
                        <InputGroup
                            className={
                                "no-border input-lg" +
                                (firstFocus ? " input-group-focus" : "")
                            }
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons ui-1_lock-circle-open"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="newPassword"
                                placeholder="New Password"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                onFocus={() => setFirstFocus(true)}
                                onBlur={() => setFirstFocus(false)}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                <button className="btn-show-password" type="button" onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? "HIDE" : "SHOW"}</button>
                                </InputGroupText>
                            </InputGroupAddon>
                            <UncontrolledPopover trigger="focus" placement="left" target="newPassword">
                                <PopoverHeader style={{ textAlign: "center", color: "black" }}>New Password Strength</PopoverHeader>
                                <PopoverBody style={{ fontSize: "12px" }}>
                                    <ul>
                                        <li>Must NOT be the same old password</li>
                                        <li>Must be {MIN_PASSWORD_LENGTH} to {MAX_PASSWORD_LEGNTH} characters</li>
                                        <li>Have at least 1 upper case character</li>
                                        <li>Have at least 1 lower case character</li>
                                        <li>Have at least a number OR symbol (!@#\$%^\&-_+=)</li>
                                    </ul>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </InputGroup>
                        <InputGroup
                            className={
                                "no-border input-lg" +
                                (lastFocus ? " input-group-focus" : "")
                            }
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons ui-1_lock-circle-open"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="confirmPassword"
                                placeholder="Confirm New Password"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                onFocus={() => setLastFocus(true)}
                                onBlur={() => setLastFocus(false)}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                <button className="btn-show-password" type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? "HIDE" : "SHOW"}</button>
                                </InputGroupText>
                            </InputGroupAddon>
                            <UncontrolledPopover trigger="focus" placement="left" target="confirmPassword">
                                <PopoverHeader style={{ textAlign: "center", color: "black" }}>Confirm New Password</PopoverHeader>
                                <PopoverBody style={{ fontSize: "12px" }}>
                                    Must match with the New Password
                                </PopoverBody>
                            </UncontrolledPopover>
                        </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button block color="info" className="btn-lg btn-round reset-button" type="submit">{'Reset Password Now'}</Button>
                    </CardFooter>
                </Form>
            </Card>
        </>
    );
};
export default connect(mapStateToProps, { passwordForgot, setUINotification })(ResetRequestCard);