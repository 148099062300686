export const SIGNUP_REQ = "SIGNUP_REQ";
export const signupRequest = (details) => {
    return {
        type: SIGNUP_REQ,
        payload: {
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
            password: details.password,
            orgCode: details.orgCode
        }
    };
};

export const SIGNUP_REQ_OK = "SIGNUP_REQ_OK";
export const signupRequestOK = (msg) => {
    return {
        type: SIGNUP_REQ_OK,
        payload: {
            signupMessage: msg
        }
    };
};

export const SIGNUP_VERIF = "SIGNUP_VERIF";
export const signupVerification = (email, signupToken) => {
    return {
        type: SIGNUP_VERIF,
        payload: {
            signupToken: signupToken,
            email: email
        }
    };
};

export const SIGNUP_VERIF_OK = "SIGNUP_VERIF_OK";
export const signupVerificationOK = (msg) => {
    return {
        type: SIGNUP_VERIF_OK,
        payload: {
            verificationMessage: msg
        }
    };
};