export const SET_LOADER = "[UI] SET_LOADER";
export const setLoader = (state, global=true) => ({
  type: SET_LOADER,
  payload: {
    data: state,
    global: global
  }
});


export const UI_ERROR = "[UI] ERROR";
export const setUIError = (uiErrorMessage) => {
  return {
    type: UI_ERROR,
    payload: {
      uiErrorMessage
    }
  };
};

const SUCCESS_ICON = "now-ui-icons ui-2_like";
const INFO_ICON = "now-ui-icons travel_info";
const WARN_ICON = "now-ui-icons ui-1_bell-53";
const DANGER_ICON = "now-ui-icons ui-1_simple-remove";
const determineIcon = (level) => {
  switch (level) {
    case SUCCESS_NOTIF_TYPE:
      return SUCCESS_ICON;
    case INFO_NOTIF_TYPE:
      return INFO_ICON;
    case WARN_NOTIF_TYPE:
      return WARN_ICON;
    case DANGER_NOTIF_TYPE:
      return DANGER_ICON;
    default:
      return INFO_ICON;
  }
}

export const SUCCESS_NOTIF_TYPE = "success";
export const INFO_NOTIF_TYPE = "info";
export const WARN_NOTIF_TYPE = "warning";
export const DANGER_NOTIF_TYPE = "danger";
export const UI_NOTIFICATION = "[UI] NOTIFICATION";
export const setUINotification = (type, msg) => {
  return {
    type: UI_NOTIFICATION,
    payload: {
      notification: {
        type: type,
        msg: msg,
        icon: determineIcon(type)
      }
    }
  }
}