// pages
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { getClient } from "./components/utils/Config";
import "./App.css";
import Login from "./components/login/Login";
import Home from "./components/search/Home";
import Search from "./components/search/Search";
import LoadingModal from "./components/utils/LoadingModal";
import { subscribeToNavigatorStatus } from "./services/BrowserLifecycleUtils";
import { MessengerChat } from "react-messenger-chat-plugin";

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.authenticated,
  errorMessage: state.authentication.errorMessage,
});

var clientData = getClient();

const App = ({ isAuthenticated }) => {
  function hookBrowserFuncs() {
    subscribeToNavigatorStatus();
    // this.props.hookOnlineStatus(ONLINE),
    // this.props.hookOfflineStatus(OFFLINE)
  }

  useEffect(() => {
    hookBrowserFuncs();
  });

  return (
    <Router>
      <main>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/search" component={Search} />
          <Route
            path="/"
            component={(props) => {
              if (isAuthenticated) {
                return <Home {...props} />;
              }
              return <Redirect to="/login" />;
            }}
          />
          <Route component={Login} />
        </Switch>
        <LoadingModal />
        {!!clientData.fb.pageId && (
          <MessengerChat pageId={clientData.fb.pageId} />
        )}
      </main>
    </Router>
  );
};
export default connect(mapStateToProps)(App);
