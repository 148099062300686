import React, { useEffect, useState } from "react";
import "react-notification-alert/dist/animate.css";
import { connect } from "react-redux";
import { Button, Card, Modal } from "reactstrap";
import {
  signupRequestOK,
  signupVerificationOK,
} from "../../redux/actions/signup.actions";
import {
  setUINotification,
  SUCCESS_NOTIF_TYPE,
} from "../../redux/actions/ui.actions";
import "./Signup.scss";
import SignupCard from "./SignupCard";
import SignupCodeCard from "./SignupCodeCard";
import SignupSuccessfulCard from "./SignupSuccessfulCard";

export const GENERIC_RESPONSE_SUCCESSFUL = "SUCCESS";

const mapStateToProps = (state) => ({
  signupMessage: state.signup.signupMessage,
  verificationMessage: state.signup.verificationMessage,
});

const SignupModal = ({
  signupMessage,
  verificationMessage,
  signupRequestOK,
  signupVerificationOK,
  setUINotification,
}) => {
  const [signupEmail, setSignupEmail] = useState("");
  const [signupModal, setSignupModal] = useState(false);

  const [codeSent, setCodeSent] = useState(false);
  const [successful, setSuccessful] = useState(false);

  useEffect(
    () => {
      if (!signupModal) {
        setCodeSent(false);
        setSuccessful(false);
      }
    },
    [signupModal]
  );

  useEffect(
    () => {
      if (signupMessage === GENERIC_RESPONSE_SUCCESSFUL) {
        setCodeSent(true);
        setUINotification(
          SUCCESS_NOTIF_TYPE,
          "Account Verification Code is sent to your associated email"
        );
        signupRequestOK(null); // Reset the message
      } else if (!signupMessage) {
        return;
      } else {
        setCodeSent(false);
      }
    },
    [signupMessage]
  );

  useEffect(
    () => {
      if (verificationMessage === GENERIC_RESPONSE_SUCCESSFUL) {
        setCodeSent(true);
        setSuccessful(true);
        signupVerificationOK(null); // Reset the message
      } else if (!verificationMessage) {
        return;
      } else {
        setSuccessful(false);
      }
    },
    [verificationMessage]
  );

  return (
    <>
      <Button
        className="btn-link forgot-link"
        color="link"
        onClick={() => setSignupModal(true)}
      >
        Not a User?
      </Button>
      <Modal
        isOpen={signupModal}
        centered={true}
        toggle={() => setSignupModal(false)}
        backdrop={true}
        className="signup-modal"
      >
        <Card className="card-plain">
          {
            signupModal && !codeSent ?
            ( <SignupCard setSignupEmail={setSignupEmail} /> ) : 
              !successful ? 
              ( <SignupCodeCard signupEmail={signupEmail} /> ) : 
              ( <SignupSuccessfulCard setSignupModal={setSignupModal} />)
          }
        </Card>
      </Modal>
    </>
  );
};

export default connect(
  mapStateToProps,
  { signupRequestOK, signupVerificationOK, setUINotification }
)(SignupModal);
