export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LEGNTH = 16;

var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isPasswordValid = (password) => {
  if (
    password.length >= MIN_PASSWORD_LENGTH &&
    password.length <= MAX_PASSWORD_LEGNTH &&
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    (/\d/.test(password) || /\W/.test(password))
  ) {
    return true;
  }
  return false;
};

export const isEmailValid = (email) => {
  return mailformat.test(email.toLowerCase());
};

export const isEmptyOrNotDefined = (value) => {
  return !value;
};

export const isNonEmpty = (value) => {
  if (!value === false) {
    return true;
  }
  return false;
};
