import FormInput from "../../components/utils/FormInput";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Form
} from "reactstrap";
import { signupVerification } from "../../redux/actions/signup.actions";
import {
  DANGER_NOTIF_TYPE,
  setUINotification
} from "../../redux/actions/ui.actions";

const mapStateToProps = (state, ownProps) => ({
  signupEmail: ownProps.signupEmail,
});

const SignupCodeCard = ({
  signupEmail,
  signupVerification,
  setUINotification,
}) => {
  const [acctVerifCode, setAcctVerifCode] = useState("");
  const [firstFocus, setFirstFocus] = useState(false);

  const signupFully = (e) => {
    e.preventDefault();
    if (acctVerifCode === "") {
      setUINotification(DANGER_NOTIF_TYPE, "Provide Account Verification Code");
      return;
    }
    signupVerification(signupEmail, acctVerifCode);
  };

  return (
    <>
      <Form className="text-center p-5 register-form" onSubmit={signupFully}>
        <CardHeader className="text-center">
          <CardTitle className="title-up" tag="h3">
            Account Verification
          </CardTitle>
        </CardHeader>
        <CardBody>
          <FormInput
            inputType="text"
            id="email"
            valueId={signupEmail}
            icon="now-ui-icons ui-1_email-85"
            readOnly={true}
          />
          <FormInput
            inputType="text"
            id="code"
            valueId={acctVerifCode}
            onChange={setAcctVerifCode}
            icon="now-ui-icons objects_key-25"
            placeholder="Account Verification Code"
            focus={firstFocus}
            onFocus={setFirstFocus}
            popoverHeader="Account Verification Code"
            popoverMessage="Provide the Account Verification Code sent to your email"
          />          
        </CardBody>
        <CardFooter className="text-center">
          <Button
            block
            color="info"
            className="btn-lg btn-round reset-button"
            type="submit"
          >
            {"Verify Signup"}
          </Button>
        </CardFooter>
      </Form>
    </>
  );
};
export default connect(
  mapStateToProps,
  { signupVerification, setUINotification }
)(SignupCodeCard);
