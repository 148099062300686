import { apiRequest, API_ERROR, API_SUCCESS } from "../../actions/api.actions";
import { LOGIN, LOGOUT, logoutSuccess, setCurrentUser } from "../../actions/auth.actions";
import { DANGER_NOTIF_TYPE, setLoader, setUINotification } from "../../actions/ui.actions";
import { API } from "../../constants/api";
const unauthorizedError = " Invalid Credentials";

export const authMiddleware = ({ dispatch }) => next => action => {
  next(action);
  // eslint-disable-next-line default-case
  switch (action.type) {
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("email");
      localStorage.removeItem("institutionName"); 

      dispatch(logoutSuccess());
      break;
      
    case LOGIN:
      dispatch(setLoader(true));
      dispatch(
        apiRequest(
          action.payload,
          "POST",
          `${API.GET_SOLOMON_API()}${API.SOLOMON_LOGIN_ENDPOINT}`,
          LOGIN
        )
      );
      break;

    case `${LOGIN} ${API_SUCCESS}`:
      const {firstName, lastName, email, institutionName, token, status} = action.payload.data;
      if(token){
        dispatch(setLoader(false));
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);
        localStorage.setItem("email", email);
        localStorage.setItem("institutionName", institutionName);
        localStorage.setItem("token", token);

        dispatch(setCurrentUser(action.payload.data));      
      }
      if(status === 401) {
        dispatch(setLoader(false));
        dispatch(setUINotification(DANGER_NOTIF_TYPE, unauthorizedError));
        // dispatch(loginFailed(unauthorizedError));
      }
      break;

    case `${LOGIN} ${API_ERROR}`:
      dispatch(setLoader(false));
      localStorage.removeItem("token");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("email");
      localStorage.removeItem("institutionName"); 
      break;
  } 
};
